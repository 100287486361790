<template>
  <div class="relative">
    <t-dropdown
      :classes="{
        dropdown: 'bg-white-900 shadow-xl w-max',
      }"
    >
      <button
        slot="trigger"
        slot-scope="{ mousedownHandler, blurHandler, keydownHandler, isShown }"
        class="
          flex
          items-center
          text-base
          font-medium
          hover:text-grey-700
          cursor-pointer
          group
        "
        @mousedown="mousedownHandler"
        @blur="blurHandler"
        @keydown="keydownHandler"
      >
        {{ selectList.find((x) => x.code === value).label }}
        <svg
          class="ml-2 group-hover:fill-current"
          :class="{ 'rotate-180 transform': isShown }"
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1L5 5L9 1"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>

      <div class="px-3 pt-2 pb-3" slot-scope="{ hide, blurHandler }">
        <span class="block uppercase text-grey-700 font-medium text-sm mb-2"
          >Sort by</span
        >
        <ul class="space-y-3">
          <li v-for="(item, index) in selectList" :key="index" class="flex">
            <button
              class="
                flex
                items-center
                justify-between
                w-full
                text-black-600 text-base
                hover:text-orange-900
              "
              :class="{ 'text-orange-900': item.code === value }"
              @blur="blurHandler"
              @mousedown="
                () => {
                  $emit('input', item.code);
                  hide();
                }
              "
            >
              {{ item.label }}

              <svg
                class="flex-shrink-0 ml-3 opacity-0"
                :class="{ 'opacity-100': item.code === value }"
                width="12"
                height="10"
                viewBox="0 0 12 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 5.8L5.21053 9L11 1"
                  stroke="#DB603B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </li>
        </ul>
      </div>
    </t-dropdown>
  </div>
</template>

<script>
export default {
  name: "Select",
  props: {
    selectList: {
      type: Array,
      required: true,
    },
    value: {
      required: true,
    },
  },
};
</script>
